.cgs-top-wrapper {
    padding: 20px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Source Sans Pro";

    /*font-family: 'Maven Pro', sans-serif;*/
    /*font-family: 'Economica', sans-serif;*/
    /*letter-spacing: 1px;*/
}
.scrollable {
    overflow: scroll;
}
.w3-modal {
    z-index: 10;
}
.no-screen-update {
    height: 150px;
    width: 224px;
    line-height: 128px;
}
.react-daterange-picker{
    width: 100%;
    .react-calendar{
        margin-top:10px;
        width: 100%;
        border:none !important
    }
    .react-daterange-picker__calendar{
        width: 100%;
    }
}
.zcalo-markdown{
    font-size: 16px;
}
.activity-scroller {
    overflow: scroll;
    // width: 100%;
    height: 120px;
    table {
        width: 100%;
        .activity-cell {
            width: 20px;
            height: 118px;
            position: relative;
            .hover-text {
                z-index: 100;
                display: none;
                width: 60px;
                font-size: 10px;
                padding: 0px 4px;
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .hover-img {
                z-index: 100;
                display: none;
                height: 100px;
                position: absolute;
                top: 0px;
                left: 0px;
            }
            &:hover {
                .hover-img {
                    display: block;
                }
                .hover-text {
                    display: block;
                }
            }
        }
    }
}

.user-screen {
    height: 128px;
    width: 224px;
    object-fit: contain;
}


.screenshot {
    height: 128px;
    min-width: 228px; 
    width:100%;
}

.block-margin {
    margin: 6px 6px 6px 0px;
}
.skrworkrow {
    display: block;
}
.skrworkcell {
    display: inline-block;
    width: 200px;
}

.edit_options {
    display: none;
}

button {
    outline: 0;
}
.progress-label { 
    margin-left: 8px;
    font-size: 12px;
    padding: 0px 6px 2px 6px;
}
ol.team-start {
    counter-reset: teamCounter;
    padding-left: 0px;
    ol {
        padding-left: 0px;
    }
    li {
        list-style: none;
        padding: 4px 0px 0px 20px;

       
    }
    li:before {
        counter-increment: teamCounter;
        content: counters(teamCounter, ".") ". ";
    }
}

@media (min-width: 1024px) {
    .w3-col.m1 {
        width: 8.33333%;
    }
    .w3-col.m2 {
        width: 16.66666%;
    }
    .w3-col.m3,
    .w3-quarter {
        width: 24.99999%;
    }
    .w3-col.m4,
    .w3-third {
        width: 33.33333%;
    }
    .w3-col.m5 {
        width: 41.66666%;
    }
    .w3-col.m6,
    .w3-half {
        width: 49.99999%;
    }
    .w3-col.m7 {
        width: 58.33333%;
    }
    .w3-col.m8,
    .w3-twothird {
        width: 66.66666%;
    }
    .w3-col.m9,
    .w3-threequarter {
        width: 74.99999%;
    }
    .w3-col.m10 {
        width: 83.33333%;
    }
    .w3-col.m11 {
        width: 91.66666%;
    }
    .w3-col.m12 {
        width: 99.99999%;
    }
}
@media (min-width: 1440px) {
    .w3-col.l1 {
        width: 8.33333%;
    }
    .w3-col.l2 {
        width: 16.66666%;
    }
    .w3-col.l3 {
        width: 24.99999%;
    }
    .w3-col.l4 {
        width: 33.33333%;
    }
    .w3-col.l5 {
        width: 41.66666%;
    }
    .w3-col.l6 {
        width: 49.99999%;
    }
    .w3-col.l7 {
        width: 58.33333%;
    }
    .w3-col.l8 {
        width: 66.66666%;
    }
    .w3-col.l9 {
        width: 74.99999%;
    }
    .w3-col.l10 {
        width: 83.33333%;
    }
    .w3-col.l11 {
        width: 91.66666%;
    }
    .w3-col.l12 {
        width: 99.99999%;
    }
}

@media screen and (max-width: 1120px) and (min-width: 421px) {
    .cgs-mw-1 {
        width: 8.333%;
    }
    .cgs-mw-2 {
        width: 16.67%;
    }
    .cgs-mw-3 {
        width: 25%;
    }
    .cgs-mw-4 {
        width: 33.33%;
    }
    .cgs-mw-5 {
        width: 41.67%;
    }
    .cgs-mw-6 {
        width: 50%;
    }
    .cgs-mw-7 {
        width: 58.33%;
    }
    .cgs-mw-8 {
        width: 66.67%;
    }
    .cgs-mw-9 {
        width: 75%;
    }
    .cgs-mw-10 {
        width: 83.33%;
    }
    .cgs-mw-11 {
        width: 91.67%;
    }
    .cgs-mw-12 {
        width: 100%;
    }
}
nav {
    background: rgba(0, 0, 0, 0.5);
    /*position: fixed;*/
    /*width: 100%;*/
    /*top: 0px;*/
    z-index: 100;
}

.cgs-works {
    /*background: url('https://d1xp9ts0bx8s2q.cloudfront.net/website/banners/top_2.jpg');*/
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}
.bold-text {
    font-weight: bold;
}
@keyframes pulse {
    0% {
    //   transform: scale(1); 
    margin-left: 10%;
    } 
      100% {
        margin-left: 0px;
    //   transform: scale(1);
    //   box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }
.info-alternate{
    .bg-transparent {
        background: rgba(220,220,255,0.92);
    }
    div.parent {
        &:hover{ 
            margin-left: 0px;
            animation: pulse 0.5s normal forwards;
        } 
    }
    div {  
        margin-left:5%;
        width: 95%;
       
    }
   
}

.scroll-table {
    tr {
        td {
            max-width: 200px;
        }
    }

     tr:nth-child(even) {
        td {
            background-color: #f1f1f1;
        }
    }
    tr:nth-child(odd) {
        td {
            background-color: #ffffff;
        }
    }
    .sticky-col {
        position: -webkit-sticky;
        position: sticky; 
        background-color: white;
    }
      
    .first-col {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        left: 0px;
    }
}


#services {
    background: red;
    background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/691408807.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    .image_text_visibility {
        background: rgba(23, 0, 0, 0.75);
    }
}

.cgs-border-top-green {
    border-top: solid 4px #6be70a;
}

.cgs-border-top-red {
    border-top: solid 4px #f53527;
}
#works {
    background: red;
    background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/works_bg.jpeg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    .image_text_visibility {
        background: #091f27bf;
    }
}

@media screen and (max-width: 639px) {
    .top_section {
        background: red;
        background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/691408807_lr.jpg?r=1");
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        /*padding-top:54px;*/
    }
    .top_section .high-res {
        background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/691408807.jpg");
        background-size: cover;
        background-position: center;
    }

    #contact_us {
        background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/contact_2.jpg");
        background-size: cover;
        background-position: center;
    }
    #contact_us .high-res {
        background: url("https://d1xp9ts0bx8s2q.cloudfront.net/website/services/contact_2.jpg");
        background-size: cover;
        background-position: center;
    }
}

.cgs-progress-text {
    margin-top: 4px;
    .cgs-status-text {
        font-size: 12px;
        letter-spacing: 0.5px;
    }
}

.image_text_visibility {
    background: rgba(0, 0, 0, 0.65);
    color: white;
}
.promo {
    font-size: 2em;
    font-weight: 100;
}

.cgs-muted {
    font-size: 10px;
    color: #333;
    margin-left: 4px;
    padding: 2px 4px;
}

.cgs.w3-table td {
    padding: 4px 4px;
    max-width: 250px;
    min-width: 250px;
}
.cgs.w3-bar {
    overflow: visible;
    .w3-button {
        position: relative;
        overflow: visible;
        &.cgs-tab-bar {
            border-radius: 4px 4px 0px 0px;
        }
        .w3-round {
            position: absolute;
            right: -12px;
            top: -6px;
            z-index: 1;
            padding: 2px 6px;
            min-width: 14px;
            font-size: 12px;
        }
    }
}
.header-container {
    position: fixed;
    top: 0px;
    z-index: 5;
    width: calc(100% - 220px);
    @media screen and (max-width: 1024px) and (min-width: 0px) {
       width:100%;
    }
    
}
footer {
    a {
        text-decoration: none;
    }
}
.bg-transparent {
    background:rgba(255,255,255,0.9);
    color: #333;
}
.cgs.w3-tag {
    margin: 2px;
}
.cgs-markdown {
    text-align: left;
    input {
        margin: 0px 6px 0px 2px;
    }
}
.page-faq{
    h3{
        color:#5847e4;
        font-weight: 800;
    }
    // :not(h3):not(h1){
        // display: none;
    // }
    p {
        padding: 0px 32px;
    }
}
.cgs-kanban-col {
    width: 280px;
    border-left: solid 1px #ccc;
}
.cgs-list-milestone {
    span.w3-tiny {
        padding: 2px 4px;
        margin: 0px 2px;
    }
}
.cgs-small-tag {
    margin: 4px 4px 0px 0px;
    border-radius: 2px;
    padding: 0px 5px;
    display: inline-block;
}
.cgs-issue-block {
    border-bottom: solid 1px #666;
    border-left: solid 1px #666;
    margin-top: 6px;
    padding: 4px 0px 4px 4px;
    a {
        text-decoration: none;
    }
}

.cgs-capitalize {
    text-transform: capitalize;
}

.cgs-plain-link {
    text-decoration: none;
}
.cgs-table {
    display: inline-flex;
}

.cgs-table.w3-table {
    display: table;
    td {
        position: relative;
        border-left: solid 1px #fff;
    }
}

.cgs-services-card {
    min-height: 240px;
}

.cgs-col {
    width: 260px;
    margin: 2px;
    display: inline-block;
}

#contact_form label {
    margin: 0.6em 0px 0.2em 0px;
    display: block;
}

#contact_form select {
    height: 40px;
}
#contact_form textarea {
    height: 260px;
}

.app-downoad {
    margin-top: 32px;
}

.download-cta {
    height: 72px;
}

.download-cta.apple {
    padding: 12px;
}

.cgs-loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 10px;
}

.cgs-loader-small {
    .cgs-loader {
        border: 2px solid #f3f3f3; /* Light grey */
        border-top: 2px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 10px;
        height: 10px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cgs-works .w3-button:hover {
    border-bottom: solid 2px #d5ff05 !important;
    background: none;
    color: #d5ff05 !important;
}

.cgs-works .w3-button.active {
    border-bottom: solid 2px #05cbff;
    color: #05cbff;
}
.cgs-i-progress {
    border: solid 2px white;
    margin-top: 4px;
    background: white;
    border-radius: 2px;
    position: relative;
    width: 100%;
    .prog {
        max-width: 100%;
        height: 6px;
    }
}
.cgs-works .w3-button {
    border-bottom: solid 2px white;
    margin: 4px 2px 10px 2px;
    background-color: transparent !important;
}
.cgs-news a {
    margin: 4px 10px;
}
.w3-modal {
    background: rgba(0, 0, 0, 0.8);
}
.cgs-tech {
    margin-top: 20px;
}
.w3-modal-content h4.with-border {
    display: inline-block;
    padding: 2px 6px;
    border-bottom: solid 2px black;
}
.w3-modal-content h4 :after {
    content: "\A";
    white-space: pre;
}
.w3-dropdown-content {
    min-width: 400px;
}
.w3-modal-content iframe {
    width: 560px;
    height: 315px;
    margin: 10px;
}

.login-bg {
    background: url("https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/zcalo/img/bg.jpg");
     background-repeat: no-repeat;
     background-size: cover;
    min-height: calc( 100vh - 40px)
}

video {
    //  filter: blur(4px) invert(1) opacity(0.5);
    // filter: hue-rotate(180deg) saturate(200%)
}
@media screen and (max-width: 540px) and (min-width: 0px) {
    .w3-modal-content iframe {
        width: 320px;
        height: 180px;
    }
}

.cgs-tech.collab span {
    background: #4908d8;
    border: solid 1px #4908d8;
}

.cgs-tech span {
    border-radius: 3px;
    border: solid 1px black;
    margin: 0px 4px 4px 0px;
    padding: 0px 8px;
    display: inline-block;
    background: black;
    color: white;
}

.flex-all-center .w3-bar-item:hover {
    border-bottom: solid 2px white;
}

.flex-all-center .w3-bar-item {
    border-bottom: solid 2px transparent;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-center a {
    margin: 0px 20px;
}
.flex-all-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
}

.flex-works {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    max-width: 932px;
    margin: 0px auto 40px auto;
}

@media screen and (min-width: 691px) and (max-width: 919px) {
    .flex-works {
        max-width: 690px;
    }
}

@media screen and (max-width: 690px) {
    .flex-works {
        max-width: 320px;
    }
    .product-item {
        width: 152px !important;
    }
}

.footer-logo {
    height: 80px;
}

.product-image {
    width: 100%;
    margin: 0px;
    max-height: 100%;
    margin: auto;
    /* filter:grayscale(100%) */
}

.product-overlay {
    position: absolute;
    // background: rgba(0,0,0,0.1);
    box-shadow: inset 0px 0px 10px 2px rgba(100, 100, 100, 0.4);

    width: 100%;
    height: 100%;
    top: 0%;
    right: 0%;
    display: none;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px black;
}

/* The animation code */
@keyframes bottomUp {
    from {
        top: 100%;
        right: 0%;
    }
    to {
        top: 0%;
        right: 0%;
    }
}

.product-overlay span.more {
    position: absolute;

    border-radius: 2px;
    padding: 4px 10px;
    background: rgba(0, 0, 0, 0.8);
    right: 10px;
    top: 10px;
    display: inline-block;
    font-size: 0.75em;
    color: white;
}

.product-overlay span i {
    margin-left: 4px;
}
@media (hover: hover) {
    .product-item:hover .product-overlay {
        display: block;
        animation-name: bottomUp;
        animation-duration: 0.4s;
        top: 0px;
    }
}

.product-item:hover .product-image {
    filter: grayscale(0%);
}
.product-item {
    width: 222px;
    position: relative;
    overflow: hidden;
    background: white;
    border: solid 1px #aaa;

    margin: 4px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.w3-modal.alertModal {
    .w3-modal-content {
        width: 480px;
    }
}

.cls-flagged {
    display: none;
}

.cls-bad {
    display: none;
}

.cls-good {
    display: none;
}

.show-good {
    .cls-good {
        display: block;
    }
}

.show-bad {
    .cls-bad {
        display: block;
    }
}
.show-flagged {
    .cls-flagged {
        display: block;
    }
}
.action-tm {
    margin: 4px  auto 4px 4px;
    padding:4px 8px;
    max-width: 200px;
    text-align: left;
    .action-prog { 
        margin:  4px 0px;
        div {
            height:8px;
        }
    }
    &.cls-flagged {
        background:red;
        color:white;
    }
    &.cls-good {
        background: #6be70a;
    }
    &.cls-bad {
        background: orange;
    }
}
.admin-table{
    td {
        min-width: 140px;
    }
}
.zcalo-doc {
    img {
        max-width:80%;
        margin-left: 10%;
        max-height:60vh;
    }
}
.no-scroll {
    overflow: hidden;
}
#modal_content h3.w3-center {
    margin-bottom: 30px;
    border-bottom: solid 2px;
    padding-bottom: 10px;
    font-size: 2em;
}
.flex-all-center .flex-item {
    flex-grow: 1;
    width: 33.33%;
    padding: 1em;
}
.eq-3 h4 {
    text-align: center;
}
.lead {
    text-align: left;
}
.eq-3 div {
    padding: 1em;
    background: rgba(8, 119, 96, 0.4);
    align-self: stretch;
}
.eq-3 {
    padding: 1em;
    align-self: stretch;
    display: flex;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation:
        fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}
